import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Header from './components/header/header';
import Footer from './components/footer/footer';

import './layout.scss';

const Layout = ({ children, hideHeader, hideFooter, selectedMenu, hideBackground, footerVersion, address, description, city, noMargin = false }) => {
  const location = useLocation();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <div className='layout-container'>
      <Header
        display-if={!hideHeader}
        selectedMenu={selectedMenu}
        hideBackground={hideBackground}
        address={address}
        description={description}
        onHeightChange={setHeaderHeight}
        city={city}
      />
      <div className='layout-children-container' style={{ marginTop: isMobile || !noMargin ? headerHeight : 0 }}>
        {children}
      </div>
      <Footer hideFooter={hideFooter} footerVersion={footerVersion} />
    </div>
  )
};

export default Layout;