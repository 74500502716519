export const PROJECTS =
[
  {
    "address": "105 Stedman Street, Brookline",
    "city": "Brookline",
    "description": "Modern Haven - modern new construction single-family house consisting of 5 bedrooms, 4.5 bathrooms and expansive decks",
    "photos": [
      "assets/portfolio/stedman/stedman-0.jpg",
      "assets/portfolio/stedman/stedman-1.jpg",
      "assets/portfolio/stedman/stedman-2.jpg",
      "assets/portfolio/stedman/stedman-3.jpg",
      "assets/portfolio/stedman/stedman-4.jpg",
      "assets/portfolio/stedman/stedman-5.jpg",
      "assets/portfolio/stedman/stedman-6.jpg"
    ],
    "isPublish": true
  },
  {
    "address": "80 Seaver Street, Brookline",
    "city": "Brookline",
    "description": "Fisher Hill Jewel – major renovation of single-family architectural beauty with carriage house and sweeping grounds",
    "photos": [
      "assets/portfolio/seaver/seaver-1.jpg",
      "assets/portfolio/seaver/seaver-2.jpg",
      "assets/portfolio/seaver/seaver-3.jpg",
      "assets/portfolio/seaver/seaver-4.jpg",
      "assets/portfolio/seaver/seaver-5.jpg",
      "assets/portfolio/seaver/seaver-6.jpg",
      "assets/portfolio/seaver/seaver-7.jpg",
      "assets/portfolio/seaver/seaver-8.jpg"
    ],
    "isPublish": true
  },
  {
    "address": "190 Lee Street, Brookline",
    "city": "Brookline",
    "description": "Colonial with Landscape Galore - gut renovation & expansion of single-family house consisting of 5 bedrooms, 6.5 bathrooms and expansive grounds",
    "photos": [
      "assets/portfolio/lee/lee-0.png",
      "assets/portfolio/lee/lee-1.png",
      "assets/portfolio/lee/lee-2.png",
      "assets/portfolio/lee/lee-3.png",
      "assets/portfolio/lee/lee-4.png",
      "assets/portfolio/lee/lee-5.png",
      "assets/portfolio/lee/lee-6.png"
    ],
    "isPublish": true
  },
  {
    "address": "33 Taylor Crossway, Brookline",
    "city": "Brookline",
    "description": "Brick Colonial Gem - gut renovation & expansion of single-family house consisting of 6 bedrooms and 5.5 bathrooms",
    "photos": [
      "assets/portfolio/taylor/taylor-0.png",
      "assets/portfolio/taylor/taylor-1.png",
      "assets/portfolio/taylor/taylor-2.png",
      "assets/portfolio/taylor/taylor-3.png",
      "assets/portfolio/taylor/taylor-4.png"
    ],
    "isPublish": true
  },
  {
    "address": "15 Gibbs Street, Brookline ",
    "city": "Brookline",
    "description": "Rowhouse Revival - gut renovation of multiple condominium units",
    "photos": [
      "assets/portfolio/gibbs/gibbs-0.png",
      "assets/portfolio/gibbs/gibbs-1.png",
      "assets/portfolio/gibbs/gibbs-2.png",
      "assets/portfolio/gibbs/gibbs-3.png",
      "assets/portfolio/gibbs/gibbs-4.png"
    ],
    "isPublish": true
  },
  {
    "address": "1575 Beacon Street, Brookline",
    "city": "Brookline",
    "description": "Chic brownstone condominium renovation",
    "photos": [
      "assets/portfolio/beacon/beacon-0.png",
      "assets/portfolio/beacon/beacon-1.png",
      "assets/portfolio/beacon/beacon-2.png",
      "assets/portfolio/beacon/beacon-3.png",
      "assets/portfolio/beacon/beacon-4.png"
    ],
    "isPublish": true
  },
  {
    "address": "117 Clinton Road, Brookline",
    "city": "Brookline",
    "description": "Brick Colonial Beauty - gut renovation of single-family house consisting of 6 bedrooms and 5.5 bathrooms",
    "photos": [
      "assets/portfolio/clinton/clinton-0.png",
      "assets/portfolio/clinton/clinton-1.png",
      "assets/portfolio/clinton/clinton-2.png",
      "assets/portfolio/clinton/clinton-3.png",
      "assets/portfolio/clinton/clinton-4.png"
    ],
    "isPublish": true
  },
  {
    "address": "77 Salisbury Road, Brookline",
    "city": "Brookline",
    "description": "Gut renovation & expansion of single-family house consisting of 6 bedrooms and 5.5 bathrooms",
    "photos": [
      "assets/portfolio/salisbury/salisbury-0.png",
      "assets/portfolio/salisbury/salisbury-1.png",
      "assets/portfolio/salisbury/salisbury-2.png",
      "assets/portfolio/salisbury/salisbury-3.png",
      "assets/portfolio/salisbury/salisbury-4.png"
    ],
    "isPublish": true
  }

]