import React from 'react';
import clsx from 'clsx';

import ContactInfo from 'components/contact-info/contact-info';
import { CONTACT_INFO } from 'constants';

import './footer.scss';

const Footer = ({ footerVersion, hideFooter }) => {

  return (
    <div className={clsx('footer__wrapper', hideFooter && 'footer__hidden')}>
      <div className='footer__container'>
        <img
          src='assets/paz-realty-logo.svg'
          className='footer__logo'
        />
        <span className='footer__title'>Excellence in real estate development</span>
        <p className='footer__copyright'>© Paz Realty 2023<br />Designed by BigioDesign</p>
      </div>

      <div className='footer__container-mobile'>

        <ContactInfo version={footerVersion} />

        {/* <div className='footer__container-mobile-content'>
          <span className='footer__title'>Paz Realty</span>
          <span className='footer__info'>Brookline, Massachusetts</span>
          <a href='www.pazrealty.com' className='footer__info'>www.pazrealty.com</a>
          <span className='footer__info'>617-817-0773</span>
        </div>

        <div className='footer__container-mobile-content'>
          <span className='footer__info'>© Paz Realty 2023</span>
          <a href='www.pazrealty.com' className='footer__info'>Designed by BigioDesign</a>
        </div> */}
        
      </div>
    </div>
  )
};

export default Footer;