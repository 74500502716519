import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import Menu from '../menu/menu';

import './header.scss';

const Header = ({ selectedMenu, hideBackground, address, description, onHeightChange, city }) => {
  const navigate = useNavigate();
  const { project } = useParams();
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleToggle = () => {
    setIsMenuVisible(!isMenuVisible);
  }

  useEffect(() => {
    setHeaderHeight(headerRef?.current?.offsetHeight)
    onHeightChange(headerRef?.current?.offsetHeight)
  }, [headerRef, headerRef?.current?.offsetHeight, project])

  return (
    <div className={clsx('header__container', hideBackground && 'header__no-background')} ref={headerRef}>
      <div className='header__content'>
        <img
          src={'assets/paz-realty-logo.svg'}
          className={clsx('header__logo', hideBackground && 'header__logo-oversize')}
          onClick={() => navigate('/')}
        />
        <Menu selectedMenu={selectedMenu} isVisible={isMenuVisible} toggleMenu={handleToggle} />
        <img
          src={'assets/burguer.svg'}
          className='header__burguer-menu'
          onClick={handleToggle}
        />
        <span className='header__address'>{address}</span>
        <span className='header__city'>{city}</span>
      </div>
      <span className='header__description'>{description}</span>
    </div>
  )
};

export default Header;