import React, { useState, useEffect } from 'react';

import './arrows.scss';

const Arrows = ({ showLeft, showRight, onClickLeft, onClickRight }) => {

  return (
    <div className='arrows__container'>
      <img
        src='assets/chevron.svg'
        className='arrows__chevron arrows__chevron-left'
        display-if={showLeft}
        onClick={onClickLeft}
      />
      <img
        src='assets/chevron.svg'
        className='arrows__chevron'
        display-if={showRight}
        onClick={onClickRight}
      />
    </div>
  )
};

export default Arrows;