import React from 'react';

import './portfolio-title.scss';

const PortfolioTitle = ({ title, onClick }) => {

  return (
    <div className='portfolio-title__container' onClick={onClick}>
      <span>Portfolio<span className='portfolio-title__spacer'> </span>|<span className='portfolio-title__spacer'> </span> <b>{title}</b></span>
    </div>
  )
};

export default PortfolioTitle;