export const MEMBERS = [
  {
    "name": "Sharona Taieb",
    "position": "Principal",
    "details": "Sharona began her career as an attorney and after years of practicing in various settings as a corporate attorney, has made the transition to real estate development. She has been developing homes in the Greater Boston area for over 15 years and what sets her work apart is the thought that goes into functionality and design, with every room being carefully analyzed for purpose and use. In addition, her attention to detail and craftsmanship is clearly evidenced in each and every project, whether small or big. The end result are beautiful homes that are designed for a lifetime of memories and lasting value."
  },
  {
    "name": "Eric Taieb",
    "position": "Business Manager",
    "details": "Eric Taieb manages the daily financial operations of Paz Realty, including lender relations, financial analysis and reporting, and project cost analysis. In addition, Eric has over 20 years of experience in commercial real estate valuation in the Greater Boston area, encompassing many types of real estate including multi-family apartments, office, industrial, retail strips, shopping centers, mixed use buildings, special purpose properties, affordable housing, land and development projects."
  },
]