import React from 'react';
import clsx from 'clsx';

import { CONTACT_INFO } from 'constants'

import './contact-info.scss';

const ContactInfo = ({ version, menuVariant }) => {

  return (
    <div className={clsx('contact-info__container-mobile', menuVariant && 'contact-info__container-mobile__menu-variant')}>

      <div display-if={version === CONTACT_INFO.BASIC} className='contact-info__wrapper'>
        <div className='contact-info__container-mobile-content'>
          <span className='contact-info__title'>Paz Realty</span>
          <span className='contact-info__info'>Brookline, Massachusetts</span>
          <a href='https://www.pazrealty.com' className='contact-info__info'>www.pazrealty.com</a>
          <a className='contact-info__info' href='tel:6178170773'>617-817-0773</a>
        </div>
      </div>

      <div display-if={version === CONTACT_INFO.FULL} className='contact-info__wrapper'>

        <div className='contact-info__contact-section'>
          <a className='contact-info__contact-section-mail' href='mailto:sharona@pazrealty.com'>sharona@pazrealty.com</a>
          <a className='contact-info__contact-section-web' href='https://www.pazrealty.com'>www.pazrealty.com</a>
          <a className='contact-info__contact-section-phone' href='tel:6178170773'>617-817-0773</a>
        </div>

        <div className='contact-info__container-mobile-content'>
          <span className='contact-info__info contact-info_reduced'>© Paz Realty 2024</span>
          <a href='www.pazrealty.com' className='contact-info__info contact-info_reduced'>Designed by BigioDesign</a>
        </div>

      </div>

    </div>
  )
};

export default ContactInfo;