import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Layout from 'components/layout/layout';
import { MEMBERS, MENU } from 'constants';

import './our-team.scss';
import { CONTACT_INFO } from '../../constants/contact-info';

const OurTeam = () => {
  const navigate = useNavigate();

  const TeamMember = ({ member, hasSeparator }) => {
    return (
      <div className={clsx('our-team__team-member-container', hasSeparator && 'our-team__team-member-border')}>
        <div className='our-team__team-member__member-column'>
          <div className='our-team__team-member__member-name'>{member.name}</div>
          <div className='our-team__team-member__member-position'>{member.position}</div>
        </div>
        <div className='our-team__team-member__paragraph-column'>
          <span className='our-team__team-meber__paragraph-text'>{member.details}</span>
        </div>
      </div>
    )
  }

  return (
    <Layout selectedMenu={MENU.OUR_TEAM} footerVersion={CONTACT_INFO.FULL}>
      <div className='our-team__container'>
        {
          MEMBERS.map((member, index) => <TeamMember member={member} hasSeparator={MEMBERS.length > index + 1} />)
        }
      </div>
    </Layout>
  )
};

export default OurTeam;