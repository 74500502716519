import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { getMobileSRC } from 'utils';

import Layout from 'components/layout/layout';
import Photos from './components/photos/photos';
import Arrows from 'components/arrows/arrows';
import { PROJECTS, MENU, CONTACT_INFO } from 'constants';

import './portfolio.scss';

const WIDTH = window.innerWidth;

const Portfolio = () => {
  const PADDING = 3 * WIDTH / 100
  const navigate = useNavigate();
  const { project } = useParams();
  const refArray = useRef(new Array());
  const refContainer = useRef();

  const [indexScrolled, setIndexScrolled] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);


  useEffect(() => {
    if (PROJECTS[project].photos.length === 0 || !PROJECTS[project].isPublish) return
    setIndexScrolled(0)
    setCurrentScroll(0)
    // refContainer.current.scrollTo({ left: 0 })
    cleanRefArray();
    setIndexScrolled(0)
  }, [project])

  const handleClickLeftArrow = () => {
    const scrollTo = indexScrolled - 1
    const rect = refArray.current[scrollTo].getBoundingClientRect();
    setIndexScrolled(scrollTo)
    const left = rect.left + currentScroll - PADDING
    setCurrentScroll(left)
    refContainer.current.scrollTo({ behavior: 'smooth', left })
  }

  const handleClickRightArrow = () => {
    const scrollTo = PROJECTS[project].photos.length - 1 > indexScrolled ? indexScrolled + 1 : 0
    setIndexScrolled(scrollTo)
    const rect = refArray.current[scrollTo].getBoundingClientRect();
    const left = rect.left + currentScroll - PADDING
    setCurrentScroll(left)
    refContainer.current.scrollTo({ behavior: 'smooth', left })
  }

  const cleanRefArray = () => {
    refArray.current.splice(0, refArray.current.length - PROJECTS[project].length)
  }

  const handlePortrait = (element) => {
    element.classList.add("portfolio__photos__img-item-portrait");
  }

  return (
    <Layout
      hideFooter
      selectedMenu={MENU.PORTFOLIO}
      footerVersion={CONTACT_INFO.FULL}
      address={PROJECTS[project].address.split(',')[0]}
      city={PROJECTS[project].city}
      description={PROJECTS[project].description}
    >
      <div className='portfolio__container' display-if={PROJECTS[project].photos.length > 0 && PROJECTS[project].isPublish}>
        <div className='portfolio__photos-wrapper'>
          <div className='portfolio__photos__container' ref={refContainer} key={project}>
            {
              PROJECTS[project].photos.map((p, index) =>
                <img
                  src={isMobile ? getMobileSRC(p) : p}
                  className='portfolio__photos__img-item'
                  ref={el => refArray.current[index] = el}
                  key={p}
                  onLoad={(e) => {
                    if (e.target?.width < e.target?.height) {
                      handlePortrait(refArray.current[index])
                    }
                  }}
                  loading="lazy"
                />
              )
            }
          </div>
          <Arrows
            showLeft={indexScrolled}
            showRight
            onClickLeft={handleClickLeftArrow}
            onClickRight={handleClickRightArrow}
          />
        </div>
        <div className='portfolio__address'>
          <span className='portfolio__address-semibold'>{PROJECTS[project].address.split(',')[0]}, </span>
          <span>{PROJECTS[project].address.split(',')[1]}</span>
        </div>
        <div className='portfolio__description'>{PROJECTS[project].description}</div>
      </div>
      <div className='portofolio__coming-soon' display-if={PROJECTS[project].photos.length === 0 || !PROJECTS[project].isPublish}>Coming Soon...</div>
    </Layout>
  )
};

export default Portfolio;