import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Home from 'containers/home/home';
import Profile from 'containers/profile/profile';
import About from 'containers/about/about';
import ContactUs from 'containers/contact-us/contact-us';
import OurTeam from 'containers/our-team/our-team';
import Portfolio from 'containers/portfolio/portfolio';



const Navigation = () => {

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='/our-team' element={<OurTeam />} />
      <Route path='/portfolio/:project' element={<Portfolio/>} />
    </Routes>
  )
}

export default Navigation;