import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { getMobileSRC } from 'utils';

import Layout from 'components/layout/layout';

import './about.scss';
import { MENU } from 'constants'
import { ABOUT_TEXT } from './about-text';
import { CONTACT_INFO } from '../../constants/contact-info';

const About = () => {
  const navigate = useNavigate();

  return (
    <Layout selectedMenu={MENU.ABOUT} footerVersion={CONTACT_INFO.FULL}>
      <div className='about__container'>
        <img
          src={isMobile ? getMobileSRC('assets/about-image.png') : 'assets/about-image.png'}
          className='about__image'
        />
        <div dangerouslySetInnerHTML={{ __html: ABOUT_TEXT }} className='about__text' />
        <div className='about__contact-section'>
          <a className='about_contact-section-mail' href='mailto:sharona@pazrealty.com'>sharona@pazrealty.com</a>
          <a className='about_contact-section-web' href='https://www.pazrealty.com'>www.pazrealty.com</a>
          <div className='about_contact-section-phone'>617-817-0773</div>
        </div>
      </div>
    </Layout>
  )
};

export default About;