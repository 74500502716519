import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Layout from 'components/layout/layout';
import Arrows from 'components/arrows/arrows';
import PortfolioTitle from 'components/portfolio-title/portfolio-title';
import { HOME_PHOTOS } from 'constants';

import './home.scss';

const Home = () => {
  const navigate = useNavigate();
  let timeout

  const [current, setCurrent] = useState(0)

  useEffect(() => {
    timeout = setTimeout(() => {
      nextPhoto()
    }, 3000)
  }, [current])

  const handleClickLeftArrow = () => {
    clearTimeout(timeout)
    if (current > 0) setCurrent(current - 1)
  }

  const handleClickRightArrow = () => {
    nextPhoto()
  }

  const nextPhoto = () => {
    clearTimeout(timeout)
    if (current < HOME_PHOTOS.length - 1) {
      setCurrent(current + 1)
    } else {
      setCurrent(0)
    }
  }

  const handleClickTitle = () => {
    navigate(`/portfolio/${HOME_PHOTOS[current].project}`)
  }

  return (
    <Layout hideFooter hideBackground noMargin>
      <div className='home__container'>
        {
          HOME_PHOTOS.map((photo, index) => 
          <div className='home__bg-img-container'>
            <img
              src={photo.url}
              className={clsx('home__bg-img', index === current && 'home__bg-img-active')}
            />
          </div>
          )
        }
        <span className='home__title'>Excellence in real estate development</span>
        <Arrows 
          showLeft={current !== 0}
          showRight
          onClickLeft={handleClickLeftArrow}
          onClickRight={handleClickRightArrow}
        />
        <PortfolioTitle 
          title={HOME_PHOTOS[current].title}
          onClick={handleClickTitle}
        />
      </div>
    </Layout>
  )
};

export default Home;