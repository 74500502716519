
import React, { useLayoutEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import history from 'navigation/history';
import Navigation from 'navigation/routes'
import { store, persistedStore } from 'redux-store/store'
import { PersistGate } from 'redux-persist/integration/react'

import './App.scss';
import 'styles/global.scss'

import { interceptor } from "./services";

const App = () => {


  useLayoutEffect(() => {
    try {
      interceptor(store)
    } catch (e) {
      console.log(e)
    }
  },[])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <HashRouter history={history}>
          <Navigation />
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
