import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { getMobileSRC } from 'utils';

import Layout from 'components/layout/layout';
import { MENU, CONTACT_INFO } from 'constants';

import './contact-us.scss';

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <Layout
      selectedMenu={MENU.CONTACT_US}
      footerVersion={CONTACT_INFO.BASIC}
      address={'PAZ REALTY'}
      city={'Brookline, Massachusetts'}
    >
      <div className='contact-us__container'>
        <div className='contact-us__left-column-container'>
          <div className='contact-us__info-container'>
            <a className='contact-us__info-url' href='https://www.pazrealty.com'>www.pazrealty.com</a>
            <a className='contact-us__info-email' href='mailto:sharona@pazrealty.com'>sharona@pazrealty.com</a>
            <div className='contact-us__info-phone'>617-817-0773</div>
          </div>
          <div className='contact-us__detail'>
            <span>Paz Realty</span> is a real estate development company specializing in high-end residential development in the Greater Boston area.
          </div>
        </div>
        <div className='contact-us__right-column-container'>
          <img
            src={isMobile ? getMobileSRC('assets/contact-us.png') : 'assets/contact-us.png'}
            className='contact-us__img'
          />
        </div>
      </div>
    </Layout>
  )
};

export default ContactUs;