import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import ContactInfo from 'components/contact-info/contact-info'

import './menu.scss';
import { PROJECTS, MENU, CONTACT_INFO } from 'constants';

const Menu = ({ selectedMenu, isVisible, toggleMenu }) => {
  const navigate = useNavigate();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const handleSelect = (index) => {
    setIsDropdownVisible(false)
    toggleMenu(false)
    navigate(`/portfolio/${index}`)
  }

  const handleNavigate = (route) => {
    toggleMenu(false);
    setTimeout(() => navigate(route), 1000);
  }

  const Item = ({ route, label, itemName, className, onClick }) => (
    <span 
      onClick={() => route ? handleNavigate(route) : onClick() } 
      className={clsx(className, selectedMenu === MENU[itemName] && 'menu__item-selected')}
    >
      {label}
    </span>
  )

  return (
    <div className={clsx('menu__container', isVisible && 'menu__container-visible')}>
      <div className='menu__portfolio-dropdown-container'>
        {/* <span
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          className={clsx(isDropdownVisible  && 'menu__item-selected')}
        >
          + Portfolio
        </span> */}
        <Item onClick={() => setIsDropdownVisible(!isDropdownVisible)} label='+ Portfolio' itemName={MENU.PORTFOLIO} className={'menu__portfolio'}/>
        <div className={clsx('menu__arrow-up', isDropdownVisible && 'menu__arrow-up-visible')}/>
        <ul className={clsx(isDropdownVisible && 'menu__ul-list-visible')}>
          {
            PROJECTS.map((p, index) => <li key={p.address} onClick={() => handleSelect(index)}>{p.address.split(',')[0]}</li>)
          }
        </ul>
      </div>
      {/* <span onClick={() => navigate('/about')}>About</span> */}
      <Item route='/about' label='About' itemName={MENU.ABOUT} />
      <Item route='/our-team' label='Our team' itemName={MENU.OUR_TEAM} />
      <Item route='/contact-us' label='Contact us' itemName={MENU.CONTACT_US} className='menu__contact-us'/>
      <ContactInfo version={CONTACT_INFO.BASIC} menuVariant />
    </div>
  )
};

export default Menu;