export const HOME_PHOTOS_OLD = [
  'assets/home-1.jpeg',
  'assets/home-2.jpeg',
  'assets/home-3.jpeg',
  'assets/home-4.jpeg',
  'assets/home-5.jpeg',
  'assets/home-6.jpeg',
  'assets/home-7.jpeg'
]

export const HOME_PHOTOS = [
  {
    url: 'assets/home-1.jpeg',
    title: '105 Stedman',
    project: '0'
  },
  {
    url: 'assets/home-2.jpeg',
    title: '105 Stedman',
    project: '0'
  },
  {
    url: 'assets/home-3.jpeg',
    title: '80 Seaver',
    project: '1'
  },
  {
    url: 'assets/home-4.jpeg',
    title: '1575 Beacon',
    project: '5'
  },
  {
    url: 'assets/home-5.jpeg',
    title: '190 Lee',
    project: '2'
  },
  {
    url: 'assets/home-6.jpeg',
    title: '105 Stedman',
    project: '0'
  },
  {
    url: 'assets/home-7.jpeg',
    title: '80 Seaver',
    project: '1'
  },
]